@use './vars' as *;
@use '../../vars' as *;

.builderBody {
    margin-top: 8px;
}

.addButton {
    background-color: #222;
    color: white;

    margin-left: 8px;
    padding: .25em .5em;
    border-radius: 28px;
    height: 28px;

    font-weight: bold;
    outline: none;

    font-size: 1em;
    color: white;

    border: 2px solid #666;

    outline: none;

    transition: background-color 0.15s ease-in-out, border-color 0.10s ease-in-out;

    &:hover { background-color: #333; }
    &:focus {
        background-color: #333;
        border-color: #777;
    }

    &.active:is(:hover, :focus) { background-color: #999; }
}

.deleteButton {
    background: none;
    outline: none;
    border: none;

    margin-left: 8px;
    padding: 0;

    font-size: 1.1em;
    margin-top: 0.2em;
    margin-bottom: 0.1em;

    transition: color 0.15s ease-in-out;

    color: #aaa;
    opacity: 0.5;
    cursor: disabled;

    &:not([disabled]) {
        color: #ccc;
        opacity: 1;
        cursor: pointer;

        &:hover { color: #fff; }
        &:focus { color: #fff; }
    }
}

@keyframes scaleInX {
    0% {
        max-width: 0%;
        scale: 0 1;
    }

    100% {
        max-width: 100%;
        scale: 1 1;
    }
}

.scaleInX {
    animation: scaleInX 0.15s ease-out;
    max-width: 100%;
    scale: 1 1;
    transform-origin: left center;
}

.scaleOutX {
    animation: scaleInX 0.15s ease-out reverse;
    max-width: 0%;
    scale: 0 1;
    transform-origin: left center;
}

//@keyframes scrollSideMaskLeft {
//    0% {
//        mask-position: -3em 0;
//    }
//
//    100% {
//        mask-position: 0 0;
//    }
//}
//
//@keyframes scrollSideMaskRight {
//    0% {
//        mask-position: calc(100% + 3em) 0;
//    }
//
//    100% {
//        mask-position: 100% 0;
//    }
//}

@mixin bodyStyling($color-bg-inactive, $color-bg-active) {
    width: 100%;

    > .childSelector {
        width: 100%;


        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        > span {
            color: #aaa;
            font-size: 1em;
            padding: 12px 0;
        }


        > .childSelectorButtonsBG {
            overflow-x: auto;

            background: $color-bg-active;
            &:not(:has(> *)) { background: none; }
        }

        //> .childSelectorButtonsBG > .rightFade {
        //    mask: linear-gradient(90deg,#000 calc(100% - 3em),#0002);
        //    animation-name: scrollSideMaskRight;
        //    animation-timeline: scroll(x 0 3em);
        //
        //    overflow-x: auto;
        //}
        //
        //> .childSelectorButtonsBG > .rightFade > .leftFade {
        //    mask: linear-gradient(90deg,#0002,#000 3em);
        //    animation-name: scrollSideMaskLeft;
        //    animation-timeline: scroll(x calc(100% - 3em) 100%);
        //
        //    overflow-x: auto;
        //}
        //
        // TODO: Add this but through JS

        > .childSelectorButtonsBG .buttons {
            background: $color-bg-active;

            overflow-x: auto;
            overflow-y: hidden;

            &::-webkit-scrollbar {
                height: 8px;
                background: $color-bg-active;
            }

            &::-webkit-scrollbar-thumb {
                background: $color-bg-inactive;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-corner {
                background: $color-bg-active;
            }

            &::-webkit-scrollbar, &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-corner {
                transition: filter 0.1s ease-in-out;
                filter: opacity(0);
                &:hover { filter: opacity(1); }
            }

            .childButtonWrapper {
                background-color: $color-bg-inactive;
                display: inline;
                position: relative;


                > .childButton {
                    padding: 8px 16px;
                    padding-right: 40px;
                    font-size: 1em;
                    color: white;
                    i { color: #aaa; }

                    background: $color-bg-inactive;

                    border: 1px solid mix(#fff, $color-bg-active, 5%);
                    border-bottom-color: $color-bg-active;
                    border-top-width: 2px;
                    border-bottom-width: 2px;
                    &:first-child { border-left-width: 2px; }
                    &:last-child { border-right-width: 2px; }

                    outline: none;

                    transition: background-color 0.15s ease-in-out, border-color 0.10s ease-in-out;

                    cursor: pointer;

                    &:hover, &:focus {
                        background-color: mix($color-bg-inactive, $color-bg-active, 50%);
                        border-color: mix(#fff, $color-bg-active, 10%);

                        &.active {
                            background-color: $color-bg-active;
                            border-bottom-color: $color-bg-active;
                        }
                    }


                    &[aria-selected="true"] {
                        background-color: $color-bg-active !important;
                        border-color: white;
                        border-bottom-color: $color-bg-active;
                    }
                }

                .deleteButton {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 4px;
                    right: 6px;
                    margin: 0;
                    font-size: 16px;
                    margin-top: 2px;

                    transition: color 0.15s ease-in-out;

                    color: #aaa;
                }


            }

        }
    }

    > .body {
        width: calc(100% - 32px);
        &:not(:has(> *)) { display: none; }

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 16px;

        background: $color-bg-active;
        > :first-child {margin-top: 0;}
        padding: 16px;
    }
}

.optionDescription {
    @extend %input-base;
    overflow: scroll;
    resize: vertical;
    margin-bottom: 16px;
    min-height: 4lh;
}

.builderBody {
    @include bodyStyling($step-inactive, $step-background);

    div:not([class]) {
        overflow: hidden;
        display: block;
        width: 100%;
        width: -webkit-fill-available;
    }

    > .body {
        width: calc(100% - 32px);
    }

    margin-bottom: 128px;
}

.groupWrapper {
    @include bodyStyling($group-inactive, $group-background);
}

.optionWrapper {
    @include bodyStyling($option-inactive, $option-background);
}

.flagWrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    > div {
        overflow-y: hidden !important;
        overflow-x: auto !important;
        white-space: nowrap !important;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: flex-start !important;
        width: max-content !important;
    }
}

.flagToSet {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: max-content;
    gap: 8px;
}

:has(> .flagToSet) {
    padding: 0 24px;
}

@media screen and (min-width: 1000px) {
    .optionBody {
    }

    .optionImageInputAndDisplay {
        //margin-left: 400px;
        float: right;
        width: 400px;
        margin-bottom: 16px;
    }

}

.optionImage {
    width: 100%;
    height: auto;
    max-height: 400px;
    min-height: 225px;
    &:is(svg) {
        height: 225px;
    }
    object-fit: contain;
    border-radius: 4px;
}

.imageInput {
    width: calc(100% - 24px) !important;
}

@media screen and (max-width: 1000px) {
    .optionImageInputAndDisplay {
        width: 320px;
    }
}
