@use '../../vars' as *;
@use './settingsGrid.module' as settingsGrid;

.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    row-gap: 40px; // 45678

    table {
        @extend .grid;

        td:nth-child(3) {
            padding-left: 16px;
            padding-right: 32px;
        }

        max-width: 100%;
    }

    > label {
        flex-direction: column;
    }

    > * {
        max-width: 100%;
    }
}
