@use '../../vars' as *;

.header {
    @extend %input-base;
    outline: none;
    background: none;
    border: none;

    color: #fff;

    font-size: 1.2em;
    letter-spacing: 0.15px;
    line-height: 18px;
    word-spacing: 0.3px;
    margin: 0;
    margin-bottom: 0.5em;
    font-family: inherit;
    padding: 4px;

    min-width: 128px;
    width: unset;

    display: inline-block;

    &[data-header-like-input-div] { width: max-content; }
    &[data-header-like-input-input] { width: calc(100% - 4px); }

    height: .95em;

    white-space: nowrap;
}

@keyframes animateToHover {
    0% {
        transform: scaleX(0);
        transform-origin: center;
    }
    100% {
        transform: scaleX(.75);
        transform-origin: center;
    }
}

@keyframes animateToFull {
    0% {
        transform: scaleX(0);
        transform-origin: center;
        background-color: hsla(0, 0%, 63%, 25%);
    }
    100% {
        transform: scaleX(.75);
        transform-origin: center;
        background-color: hsla(0, 0%, 82%, 100%);
    }
}

.underlineProvider{
    transition: width 0.33s ease-in-out;
    position: relative;

    &::before, &::after {
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        height: 1px;
        background-color: hsla(0, 0%, 63%, 25%);
    }

    &::after {
        transform: scaleX(0) scaleY(0.5);
        transform-origin: center;

        transition-timing-function: cubic-bezier(.33, .15, .5, .7);
        transition-property: transform, background-color;

        animation: animateToHover 0.3s cubic-bezier(.33, .15, .5, .7) reverse;
        transition-duration: 0.3s;

        bottom: -3px;
        height: 2px;
    }

    &:hover::after {
        background-color: hsla(0, 0%, 62%, 100%);
        transform: scaleX(.75) scaleY(0.75);

        transition-timing-function: cubic-bezier(.33, .15, .5, .7);
        transition-duration: 0.2s;
        animation: animateToHover 0.4s cubic-bezier(.33, .15, .5, .7);
    }

    &[data-editing="true"]::after {
        background-color: hsla(0, 0%, 82%, 100%);
        transform: scaleX(1) scaleY(0.75);

        transition-timing-function: cubic-bezier(.33, .15, .5, .7);
        transition-duration: 0.2s;
        animation: animateToHover 0.2s cubic-bezier(.33, .15, .5, .7);
    }

    &:has([data-is-recalcing-width]):is(::before, ::after) {
        transition-property: none !important;
    }
}
