.dropdown {
    color: #fff;
    width: max-content;
    min-width: 15rem;

    font-size: 1rem; // make whole dropdown use rem now
}

.container {
    color: #fff;
}

.control {
    background: #181818;
    border-radius: .25rem;
}

.dropdownIndicator {
    color: #fff;
}

.group {
    color: #fff;
}

.groupHeading {
    color: #fff;
}

.indicatorsContainer {
    color: #fff;
    padding: 0 8px;
}

.indicatorSeparator {
    color: #fff;
}

.input {
    color: #fff;
    padding: .8rem;
}

.menu {
    color: #fff;
    background: #111;
    border-radius: .3rem;
    overflow: clip;
    border: .15rem solid #222;
    margin-top: .4rem;
}

.menuList {
    color: #fff;
}

.menuPortal {
    color: #fff;
}

.option {
    color: #fff;
    padding: .8rem;

    &--focused {
        background: #181818;

    }

    //&--selected {
    //    background: #333;
    //}

    &--disabled {
        background: #000;
    }
}

.placeholder, .singleValue {
    color: #fff !important;
    padding: .8rem;
}

.placeholder, .singleValue:has(+.input > [aria-expanded="true"]) {
    color: #888 !important;
}

.valueContainer {
    color: #fff !important;
}

.keydownPropStopper {
    display: inline-block;
}
